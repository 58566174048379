
import {
    computed, defineComponent, onMounted, PropType, reactive, ref,
} from 'vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import ReservationService from '@/services/ReservationService';
import ReservationStatus from '@/domain/enums/ReservationStatus';
import ReservationType from '@/domain/enums/ReservationType';
import Direction from '@/domain/enums/Direction';
import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import ReservationTransaction from '@/domain/ReservationTransaction';
import Reservation from '@/domain/Reservation';
import { useNotification } from '@/composable/useNotifications';
import BRadioButtonGroup, { RadioButtonOption } from '@/components/bootstrap-library/BRadioButtonGroup.vue';

type State = {
    hover: boolean;
    isOverflowing: boolean;
    showSetStatusModal: boolean;
    showUpdateStatusModal: boolean;
    selectedReservationStatus: string;
    lateReason: string | undefined;
    trailerNumber: string | undefined;
};

export default defineComponent({
    name: 'existing-shipment-reservation-card',
    components: {
        BRadioButtonGroup,
    },
    props: {
        title: { type: String, required: true },
        start: { type: Date, required: true },
        end: { type: Date, required: false, default: () => undefined },
        reservation: { type: Object as PropType<Reservation>, required: true },
    },
    setup(props) {
        const reservationService = new ReservationService();

        const state = reactive<State>({
            hover: false,
            isOverflowing: false,
            showSetStatusModal: false,
            showUpdateStatusModal: false,
            selectedReservationStatus: ReservationStatus.Late.toString(),
            lateReason: props.reservation.status === ReservationStatus.Late ? props.reservation.lateReason! : '',
            trailerNumber: props.reservation.status === ReservationStatus.TrailerArrived ? props.reservation.trailerNumber! : '',
        });

        const calendarContent = ref<HTMLElement | null>(null);

        const transactionStatus = computed(() => props.reservation.transactions[0].status);
        const transactionStatusDescription = computed(() => Formatter.GetFriendlyValue(transactionStatus.value));

        const reservationStatusToSet = computed(() : ReservationStatus => parseInt(state.selectedReservationStatus, 10));

        const headerColor = computed(() => {
            if (props.reservation.reservationType === ReservationType.Planned) {
                return '#6b8e23';
            }
            if (props.reservation.reservationType === ReservationType.Shipment) {
                if (props.reservation.direction === Direction.Inbound) {
                    if (transactionStatus.value >= TransactionStatus.DELIVERY_CONFIRMED) return '#5b5b7e';
                    return '#4b61a8';
                }
                if (props.reservation.direction === Direction.Outbound) {
                    if (transactionStatus.value >= TransactionStatus.DELIVERY_CONFIRMED) return '#7c5f28';
                    return '#c48c17';
                }
            }
            return '';
        });

        const trailerStatusOptions: RadioButtonOption[] = [
            { text: getTitleCaseTranslation('core.common.trailerIsLate'), value: ReservationStatus.Late.toString() },
            { text: getTitleCaseTranslation('core.common.trailerHasArrived'), value: ReservationStatus.TrailerArrived.toString() },
        ];

        onMounted(() => {
            if (calendarContent.value) {
                state.isOverflowing = calendarContent.value.offsetHeight < calendarContent.value.scrollHeight;
            }
        });

        function displayLocation(transaction: ReservationTransaction): string {
            if (props.reservation.direction === Direction.Inbound) return transaction.fromLocationName;
            return transaction.toLocationName;
        }

        function cancelSetReservationStatus() {
            state.lateReason = props.reservation.status === ReservationStatus.Late ? props.reservation.lateReason! : '';
            state.trailerNumber = props.reservation.status === ReservationStatus.TrailerArrived ? props.reservation.trailerNumber! : '';
            state.showSetStatusModal = false;
            state.selectedReservationStatus = ReservationStatus.Late.toString();
        }

        function cancelChangeReservationStatus() {
            state.lateReason = props.reservation.status === ReservationStatus.Late ? props.reservation.lateReason! : '';
            state.trailerNumber = props.reservation.status === ReservationStatus.TrailerArrived ? props.reservation.trailerNumber! : '';
            state.showUpdateStatusModal = false;
        }

        async function flagReservationAsLate() {
            if (state.lateReason && state.lateReason.trim() !== '') {
                const response = await reservationService.flagShipmentReservationAsLate(props.reservation.shipmentId, state.lateReason);
                if (response.success) {
                    props.reservation.status = ReservationStatus.Late;
                    props.reservation.lateReason = state.lateReason;
                    state.showSetStatusModal = false;
                    state.showUpdateStatusModal = false;
                    return;
                }
            }
            useNotification().showError(getTitleCaseTranslation('core.validation.reasonForBeingLateIsRequired'));
        }

        async function markReservationTrailerArrived() {
            if (state.trailerNumber && state.trailerNumber.trim() !== '') {
                const response = await reservationService.markShipmentReservationTrailerArrived(props.reservation.shipmentId, state.trailerNumber);
                if (response.success) {
                    props.reservation.status = ReservationStatus.TrailerArrived;
                    props.reservation.trailerNumber = state.trailerNumber;
                    state.showSetStatusModal = false;
                    state.showUpdateStatusModal = false;
                    return;
                }
            }
            useNotification().showError(getTitleCaseTranslation('core.validation.trailerNumberIsRequired'));
        }

        async function markReservationOnTime() {
            const response = await reservationService.markShipmentReservationOnTime(props.reservation.shipmentId);
            if (response.success) {
                props.reservation.status = ReservationStatus.OnTime;
                props.reservation.lateReason = undefined;
                props.reservation.trailerNumber = undefined;
                state.showUpdateStatusModal = false;
            }
        }

        async function setTrailerStatus() {
            if (reservationStatusToSet.value === ReservationStatus.Late) {
                await flagReservationAsLate();
            } else if (reservationStatusToSet.value === ReservationStatus.TrailerArrived) {
                await markReservationTrailerArrived();
            }
        }

        return {
            state,
            ReservationStatus,
            ReservationType,
            Direction,
            headerColor,
            transactionStatusDescription,
            displayLocation,
            calendarContent,
            trailerStatusOptions,
            reservationStatusToSet,
            cancelSetReservationStatus,
            cancelChangeReservationStatus,
            setTrailerStatus,
            flagReservationAsLate,
            markReservationOnTime,
            markReservationTrailerArrived,
            getTitleCaseTranslation,
        };
    },
});
