export default class NonWorkDays {
    constructor(init?: Partial<NonWorkDays>) {
        Object.assign(this, init);
    }

    public id!: number;

    public nonWorkDay!: NonWorkDay;

    public nonWorkingTimeSetId!: number;
}

// This alignes with C# DayOfWeek enum and JavaScript Date.GetDay
export enum NonWorkDay {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}
