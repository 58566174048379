export default function useHtmlClassHelper() {
    function exists(elem: HTMLElement, className: string) {
        const p = new RegExp(`(^| )${className}( |$)`);
        return (elem.className && elem.className.match(p));
    }

    function addClass(elem: HTMLElement, className: string) {
        if (!exists(elem, className)) {
            // eslint-disable-next-line no-param-reassign
            elem.className += ` ${className}`;
        }
    }

    function removeClass(elem: HTMLElement, className: string) {
        let c = elem.className;
        const p = new RegExp(`(^| )${className}( |$)`);
        c = c.replace(p, ' ').replace(/ {2}/g, ' ');
        // eslint-disable-next-line no-param-reassign
        elem.className = c;
    }

    return {
        addClass, removeClass,
    };
}
